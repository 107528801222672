import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../images/logo.png";
import {
  NavBar,
  NavbarBrand,
  BasicNavbarNav,
  NavLink,
  NavLinkP,
  NavCart,
  Copyright,
  FooterLinks,
} from "./StyledComponents";

import { WholeContext } from "../App";

const FooterBar = (props) => {
  const checkoutContext = useContext(WholeContext);
  let cart = checkoutContext.state.cart;
  const { openTou, year } = props;
  return (
    <div>
      <NavBar expand="md" fixed="bottom" background="#fff">
        <NavbarBrand href="/" pullleft="true">
          <img src={logo} alt="logo" />
        </NavbarBrand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <BasicNavbarNav>
          <Nav className="mr-auto" style={{ alignItems: "center" }}>
            <NavLink href="/">Designs</NavLink>
            <NavLink href="#/faq">FAQ</NavLink>
            <NavLink href="" onClick={openTou}>
              Terms Of Use
            </NavLink>
            {cart.length ? (
              <NavLinkP background="#B398CE" to={{ pathname: "/checkout" }}>
                <NavCart>
                  <div>
                    <span style={{ fontSize: 16 }}>$ {cart.length}.00</span>
                    <div style={{ fontSize: 11 }}>Total</div>
                  </div>
                </NavCart>
              </NavLinkP>
            ) : (
              <NavLink disabled>
                <span>Your cart is empty</span>
              </NavLink>
            )}
          </Nav>
        </BasicNavbarNav>
        <NavbarBrand className="ml-2">
          <div>
            <p>Scan to download our app</p>
            <img
              src="https://cdn.explorug.com/website/only1dollardesign/only1DollarDynamicQR.png"
              alt="scan"
              width="140px"
              height="140px"
            />
          </div>
        </NavbarBrand>
        <Copyright id="copyright" textCenter>
          © Alternative Technology {year} - All rights reserved
          {/* <div>
            <FooterLinks onClick={openTou}>Terms of Use</FooterLinks>
          </div> */}
        </Copyright>
      </NavBar>
    </div>
  );
};

export default FooterBar;
